import { useMemo } from 'react';
import { getCountryCode, useFlags } from 'common';
import { useIntl } from 'react-intl';

export const useIsOnDemandAccreditationEnabled = () => {
    const { learningOnDemandAccreditationAtTemp14102024: onDemandAccreditationAt } = useFlags();

    const { locale } = useIntl();
    const countryCode = getCountryCode(locale);

    const isCountryFullySupported: boolean = useMemo(() => {
        return countryCode === 'de' || countryCode === 'ch';
    }, [countryCode]);

    const onDemandAccreditationEnabled: boolean = useMemo(() => {
        switch (countryCode) {
            case 'de':
            case 'ch':
                return true;
            case 'at':
                return onDemandAccreditationAt;
            case 'gb':
                return true;
        }
    }, [countryCode, onDemandAccreditationAt]);

    return { onDemandAccreditationEnabled, isCountryFullySupported };
};
