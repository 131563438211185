import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    pageContainer: {
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(10)
        }
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            alignItems: 'center',
            padding: theme.spacing(1)
        }
    },
    title: {
        fontWeight: '700',
        fontSize: 44,
        fontFamily: 'Poppins',
        [theme.breakpoints.down('sm')]: {
            fontSize: 36,
            textAlign: 'center'
        }
    },
    titleIcon: {
        width: 120,
        overflow: 'visible',
        height: 120,
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            height: 80,
            width: 80
        }
    },
    subtitle: {
        fontWeight: 'normal',
        fontSize: 24,
        color: '#41607D',
        margin: theme.spacing(2, 0, 4, 0),
        [theme.breakpoints.down('sm')]: {
            fontSize: 24,
            textAlign: 'center'
        }
    },
    coursesTitle: {
        fontWeight: 400,
        fontSize: 20,
        fontFamily: 'Poppins',
        color: '#41607D',
        [theme.breakpoints.down('sm')]: {
            fontSize: 18
        }
    },
    coursesSubTitleContainer: {
        margin: theme.spacing(2, 0)
    },
    benefitsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(4),
        background: 'linear-gradient(90deg, #FBFEFF 0%, #ECF9FD 100%)',
        margin: theme.spacing(8, 0),
        height: 200,
        ...theme.border({ radius: 8, color: theme.palette.body['400'] }),
        [theme.breakpoints.down('sm')]: {
            background: 'linear-gradient(0deg, #FBFEFF 0%, #ECF9FD 100%)',
            flexDirection: 'column',
            height: 'auto',
            gap: theme.spacing(0),
            padding: theme.spacing(2, 0),
            margin: theme.spacing(8, 2)
        }
    },
    benefitDivider: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        width: 80,
        borderColor: theme.palette.secondary['400']
    },
    benefitsItem: {
        fontFamily: 'Poppins',
        fontSize: 24,
        fontWeight: 'normal',
        lineHeight: 'normal',
        color: '#59748D',
        textAlign: 'center',
        width: '100%',
        minHeight: 80,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:not(:last-child)': {
            ...theme.border({
                color: theme.palette.secondary['400'],
                kind: 'right',
                radius: 0,
                width: 2
            })
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            '&:not(:last-child)': {
                border: 'none',
                borderRight: 'none !important'
            }
        }
    },
    innerContainer: {
        padding: theme.spacing(0)
    },
    benefitsSectionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        color: theme.palette.primary.main,
        margin: theme.spacing(10, 0),
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(0, 0, 4, 0)
        }
    },
    benefitsSectionTitleContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            gap: theme.spacing(2),
            alignItems: 'center'
        }
    },
    semiCircleBanner: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
            position: 'absolute',
            bottom: 'auto',
            right: 0,
            width: 350
        }
    },
    promoImagePhone: {
        width: 118,
        height: 112,
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    benefitsSectionTitle: {
        fontSize: 36,
        fontWeight: 700
    },
    benefitsSectionList: {
        listStyle: 'none',
        width: '100%',
        paddingLeft: theme.spacing(0.5)
    },
    benefitsSectionText: {
        fontSize: 20,
        color: '#59748D',
        maxWidth: 750,
        fontWeight: 400
    },
    benefitsSectionListItem: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        '& p': {
            fontWeight: 400,
            fontSize: 20
        }
    },
    membershipSectionTitle: {
        marginBottom: theme.spacing(3),
        fontSize: 32,
        fontWeight: 700
    },
    accreditationContainer: {
        margin: theme.spacing(10, 0),
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(4, 0)
        }
    },
    accreditationText: {
        color: '#59748D',
        maxWidth: 600
    },
    accreditationBadge: {
        display: 'flex',
        background: theme.palette.primary.main,
        width: 140,
        borderRadius: 6,
        color: 'white',
        position: 'relative',
        paddingLeft: 12,
        overflow: 'hidden',
        '& > div': {
            width: 137,
            backgroundColor: '#2CA8CA',
            borderRadius: '100%',
            position: 'absolute',
            left: -121,
            top: -25,
            height: 140
        }
    },
    accreditationBadgeText: {
        textTransform: 'uppercase',
        letterSpacing: '1px',
        fontWeight: 600,
        fontSize: 14,
        padding: theme.spacing(1.5)
    },
    accreditationInnerContainer: {
        display: 'flex',
        gap: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    },
    promoImage: {
        [theme.breakpoints.down('md')]: {
            display: 'none !important'
        }
    }
}));
