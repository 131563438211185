import { Box } from '@mui/material';
import classnames from 'classnames';
import { getCountryCode, isFeatureEnabled } from 'common';
import { Icon, Typography } from 'components';
import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';

export const useStyles = makeStyles(
    (theme) => ({
        container: {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1)
        },
        icon: {
            width: 24,
            height: 24,
            color: '#59748D'
        },
        title: {
            letterSpacing: 1,
            fontFamily: 'Poppins',
            color: '#59748D',
            fontWeight: 600,
            fontSize: 13,
            lineHeight: 1,
            textTransform: 'uppercase'
        }
    }),
    { name: 'ExclusiveForMembersLabel' }
);

export const ExclusiveForMembersLabel = (props: { className?: string }) => {
    const { locale } = useIntl();
    const classes = useStyles();
    const countryCode = getCountryCode(locale);

    return isFeatureEnabled('memberships', countryCode) ? (
        <Box className={classnames(props.className, classes.container)}>
            <Icon className={classes.icon} icon="checkmarkLight" />
            <Typography
                variant="overline"
                className={classes.title}
                localeId="catalog.exclusive-for-members-label"
            />
        </Box>
    ) : null;
};
